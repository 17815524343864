.toma-animations {
    display: flex;
    flex-direction: row;
}

.text-area {
    display: flex;
    flex-direction: column;
    font-family: 'Fredoka One';
    margin-top: 200px;
    margin-left: -60px;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
}

.text {
    font-size: 28px;
}

.subtext {
    font-size: 18px;
}